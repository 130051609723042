@tailwind base;@tailwind components;@tailwind utilities;
/* REMOVE DRAG, HIGHLIGHT, & POINTER */
[draggable=false]{
    user-select:none;/* Chrome & Opera */
    pointer-events:none;/* General CSS */
    -o-user-select:none;/* Possibly useless*/
    -ms-user-select:none;/* IE & Edge */
    -moz-user-select:none;/* Firefox */
    -khtml-user-select:none;/* Konqueror HTML */
    -webkit-user-select:none;/* Safari */
    -webkit-touch-callout:none;/* iOS Safari */
    -webkit-tap-highlight-color:transparent;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
}
/* REMOVE SCROLL */
._disableScroll{overflow:hidden!important}
*::-webkit-scrollbar{width:0px}/* Chrome, Safari, & Opera */
*{scrollbar-width:none;/* Firefox */-ms-overflow-style: none/* IE & Edge */}